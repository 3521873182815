<template>
  <section
    v-loading.fullscreen.lock="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div v-if="isAEntityTemplateViewData && getIsMobile">
      <el-row>
        <el-col
          :xl="{ span: 8 }"
          :lg="{ span: 8 }"
          :md="{ span: 24 }"
          :sm="{ span: 4 }"
          :xs="{ span: 4 }"
        >
          <el-link
            type="primary"
            :underline="false"
            @click="SaveUnselectedData()"
            style="cursor: pointer; width: 50px"
          >
            <img
              style="cursor: pointer; width: 16px"
              src="@/assets/img/Save.svg"
            />Save
          </el-link>
        </el-col>
        <el-col
          :xl="{ span: 8 }"
          :lg="{ span: 8 }"
          :md="{ span: 24 }"
          :sm="{ span: 8 }"
          :xs="{ span: 8 }"
        >
          <el-checkbox
            v-model="is_in_progress"
            @change="isInProgress_ViewData"
            class="checkbox"
            >In Progress
          </el-checkbox>
        </el-col>
        <el-col
          :xl="{ span: 8 }"
          :lg="{ span: 8 }"
          :md="{ span: 24 }"
          :sm="{ span: 10 }"
          :xs="{ span: 10 }"
        >
          <el-button
            class="ml-1"
            type="primary"
            :disabled="!allDocumentsData.length"
            @click="downloadCSVFile"
            >Download CSV</el-button
          >
        </el-col>
        <el-select
          class="mb-1 mt-1"
          style="width: 100%"
          default-first-option
          filterable
          collapse-tags
          multiple
          v-model="tableheaders"
          @change="onChange($event)"
        >
          <el-option
            v-for="(op, key) in allTableHeaders"
            :key="key"
            :label="op"
            :value="op"
          ></el-option>
        </el-select>
      </el-row>
    </div>
    <div
      v-else-if="isAEntityTemplateViewData && !getIsMobile"
      style="margin-top: 5px; margin-left: 340px"
      class="mb-1"
    >
      <el-row>
        <el-tooltip
          placement="top-start"
          :content="
            getIsMobile
              ? ''
              : 'Enable to see In Progress (sent) documents status'
          "
        >
          <el-checkbox
            v-model="is_in_progress"
            @change="isInProgress_ViewData"
            class="checkbox"
            style="margin-top: 10px; margin-left: 250px"
            >Include In Progress
          </el-checkbox>
        </el-tooltip>
        <el-tooltip
          placement="top-start"
          :content="
            getIsMobile ? '' : 'You can filter & save selected tableheaders'
          "
        >
          <el-link
            type="primary"
            :underline="false"
            @click="SaveUnselectedData()"
            style="cursor: pointer; width: 100px"
          >
            <img
              style="cursor: pointer; width: 16px"
              src="@/assets/img/Save.svg"
            />Save
          </el-link>
        </el-tooltip>
        <el-select
          class="mb-2 mr-1"
          :style="isAEntityTemplateViewData ? 'width:50%' : 'width:80%'"
          default-first-option
          filterable
          collapse-tags
          multiple
          v-model="tableheaders"
          @change="onChange($event)"
        >
          <el-option
            v-for="(op, key) in allTableHeaders"
            :key="key"
            :label="op"
            :value="op"
          ></el-option>
        </el-select>
        <el-tooltip
          placement="top-start"
          :content="
            getIsMobile ? '' : 'Here you can download selected table data'
          "
        >
          <el-button
            style="height: 40px"
            type="primary"
            :disabled="!allDocumentsData.length"
            @click="downloadCSVFile"
            >Download CSV</el-button
          >
        </el-tooltip>
      </el-row>
    </div>
    <div v-else style="display: flex; float: right" class="mb-1">
      <el-row>
        <el-col
          :xl="{ span: 4 }"
          :lg="{ span: 4 }"
          :md="{ span: 12 }"
          :sm="{ span: 12 }"
          :xs="{ span: 12 }"
        >
          <el-tooltip
            placement="top-start"
            :content="
              getIsMobile
                ? ''
                : 'Enable to see In Progress (sent) documents status'
            "
          >
            <el-checkbox
              v-model="is_in_progress"
              @change="isInProgress_ViewData"
              class="checkbox"
              style="margin-top: 10px; margin-right: 200px"
              >Include In Progress
            </el-checkbox>
          </el-tooltip>
        </el-col>
        <el-col
          :xl="{ span: 4 }"
          :lg="{ span: 4 }"
          :md="{ span: 12 }"
          :sm="{ span: 12 }"
          :xs="{ span: 12 }"
        >
          <el-tooltip
            placement="top-start"
            :content="
              getIsMobile ? '' : 'You can filter & save selected tableheaders'
            "
          >
            <el-link
              type="primary"
              :underline="false"
              @click="SaveUnselectedData()"
              style="cursor: pointer; width: 100px; margin-top: 10px"
            >
              <img
                style="cursor: pointer; width: 16px"
                src="@/assets/img/Save.svg"
              />Save
            </el-link>
          </el-tooltip>
        </el-col>
        <el-col
          :xl="{ span: 8 }"
          :lg="{ span: 8 }"
          :md="{ span: 24 }"
          :sm="{ span: 24 }"
          :xs="{ span: 24 }"
        >
          <el-select
            class="mr-1 mb-2"
            style="width: 100%"
            default-first-option
            filterable
            collapse-tags
            multiple
            v-model="tableheaders"
            @change="onChange($event)"
          >
            <el-option
              v-for="(op, key) in allTableHeaders"
              :key="key"
              :label="op"
              :value="op"
            ></el-option>
          </el-select>
        </el-col>
        <el-col
          :xl="{ span: 6 }"
          :lg="{ span: 6 }"
          :md="{ span: 24 }"
          :sm="{ span: 24 }"
          :xs="{ span: 24 }"
        >
          <el-tooltip
            placement="top-start"
            :content="
              getIsMobile ? '' : 'Here you can download selected table data'
            "
          >
            <el-button
              class="ml-1"
              style="height: 40px"
              type="primary"
              :disabled="!allDocumentsData.length"
              @click="downloadCSVFile"
              >Download CSV</el-button
            >
          </el-tooltip>
        </el-col>
      </el-row>
    </div>
    <div v-if="tableheaders && tableheaders.length">
      <div v-if="isAEntityTemplateViewData">
        <el-table
          :data.sync="allDocumentsData"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          @sort-change="sortChange"
          class="sidemenu"
          sortable
          border
          @query-change="loadData"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            fixed
            :resizable="false"
            style="word-wrap: break-word"
            v-for="(col, index) in tableheaders"
            :key="index"
            :label="col"
            sortable="custom"
            :prop="getPropValue(col)"
            :formatter="col.formatter"
            min-width="150"
          >
            <template slot-scope="scope">
              <p v-if="scope.row && scope.row[col]">
                {{ scope.row[col] }}
              </p>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <el-table
          :data.sync="allDocumentsData"
          :fullscreen="true"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          :pagination-props="paginationProps"
          @sort-change="sortChange"
          class="sidemenu"
          sortable
          border
          @query-change="loadData"
          @selection-change="handleSelectionChange"
        >
          <el-scrollbar wrap-style="max-width: 500px">
            <el-table-column
              v-for="(col, index) in tableheaders"
              :key="index"
              :label="col"
              :prop="getPropValue(col)"
              :formatter="col.formatter"
              fixed="left"
              min-width="150"
              sortable="custom"
            >
              <template slot-scope="scope">
                <p
                  style="font-size: 12px"
                  v-if="
                    scope.row &&
                    scope.row[col] &&
                    typeof scope.row[col] === 'object' &&
                    scope.row[col].length
                  "
                >
                  {{ scope.row[col].join(",") }}
                </p>
                <p
                  style="font-size: 12px"
                  v-else-if="scope.row && scope.row[col]"
                >
                  {{ scope.row[col] }}
                </p>
              </template>
            </el-table-column>
          </el-scrollbar>
        </el-table>
      </div>
      <el-pagination
        background
        :class="
          isAEntityTemplateViewData ? 'custom-pagination' : 'custom-pagination'
        "
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="paginationProps"
        :page-size="pageSize"
        :pager-count="5"
        layout="prev, pager, next, jumper, sizes, total"
        :total="total"
      ></el-pagination>
      <!-- </ElTableDraggable> -->
    </div>
    <div
      v-else-if="!tableheaders.length && allTableHeaders.length"
      style="text-align: center"
    >
      No Column selected
    </div>
    <div
      v-else-if="unWantedFields.length"
      style="text-align: center; margin-top: 100px"
    >
      <img src="@/assets/img/nodata2.svg" />
      <p>There are no fields data for this template document.</p>
    </div>
    <div v-else style="text-align: center">No Data</div>
  </section>
</template>
<script>
import { bus } from "../../main";
import { mapGetters } from "vuex";
import store from "@/store";
import moment from "moment";

// import ElTableDraggable from "el-table-draggable";
export default {
  name: "templates-templateSubmmittedData",
  props: [
    "configurableDocumentId",
    "configurableDocumentData",
    "isAEntityTemplateData",
    "entityId",
    "entityDataId",
  ],

  components: {
    // ElTableDraggable,
  },
  data() {
    return {
      allDocumentsData: [],
      tableheaders: [],
      allTableHeaders: [],
      Doc_status: [],
      sorted_array: [],
      total: 0,
      currentPage: 1,
      pageSize: 5,
      order_type: null,
      order_by: null,
      page: 1,
      sortBy: "label",
      sortOrder: -1,
      tableheadersIndex: "",
      loadingText: "",
      result: [],
      loading: false,
      is_in_progress: false,
      isAEntityTemplateViewData: this.isAEntityTemplateData,
      download_csv: false,
      selected_page: [],
      CurrencyFieldsData: [],
      unWantedFields: [
        "INITIAL",
        "SIGNATURE",
        "ATTACHMENT",
        "IMAGE",
        "HEADING",
        "PARAGRAPH",
        "my-signature",
        "MY_INITIAL",
        "HORIZONTAL_LINE",
      ],
    };
  },
  computed: {
    ...mapGetters("documents", ["getDocumentsDataByConfigurableId"]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("configurableDocuments", [
      " getUpdateViewDataStatus",
      "getUpdateViewDataData",
      "getUpdateViewDataStatus",
      "getUpdateViewDataErrors",
    ]),
    paginationProps() {
      return [5, 10];
    },
  },
  created() {},
  async mounted() {
    this.loading = true;
    await this.fetchDocumentData();
    bus.$on("document-change", async (data) => {
      console.log(data);
      this.allDocumentsData = [];
      this.tableheaders = [];
      this.allTableHeaders = [];
      await this.fetchDocumentData();
    });
    this.loading = false;
  },
  methods: {
    formatter(row, col, cell, index) {
      this.tableheadersFormatter(cell);
      console.log(row, col, index); //example of a formatter
    },
    onChange(event) {
      this.tableheadersIndex = event;
    },
    getPropValue(step) {
      // if (step.type == "ENTITY") {
      //   return step.template_id + "#" + step.id + "/name";
      // }
      return step;
    },
    handleSelectionChange(val) {
      this.selected_page = [];
      this.selected_page = val;
    },
    changeTableColumns() {
      console.log(this.allTableHeaders);
    },
    goBackToTemp() {
      this.$router.push({
        path: "/templates",
      });
    },
    async sortChange(a) {
      const { prop, order } = a;
      this.allDocumentsData.sort((a, b) => {
        const val1 = a[prop];
        const val2 = b[prop];
        if (order === "ascending") {
          return val1.localeCompare(val2);
        } else {
          return val2.localeCompare(val1);
        }
      });
    },
    async downloadCSVFile() {
      try {
        this.download_csv = true;
        let params = {};
        if (this.isAEntityTemplateViewData) {
          params = {
            id: this.configurableDocumentId,
            entity_id: this.$route.params.entity_id,
            entity_data_id: this.entityDataId,
            downloadCsvFlag: this.download_csv,
            include_is_in_progress: true,
            isInProgress: this.is_in_progress,
          };
        } else {
          params = {
            id: this.configurableDocumentId,
            downloadCsvFlag: this.download_csv,
            include_is_in_progress: true,
            isInProgress: this.is_in_progress,
          };
        }
        await this.$store.dispatch(
          "documents/fecthDocumentsDataByConfigurableId",
          params
        );
        await this.$store.dispatch(
        "configurableDocuments/fetchConfigurableDocumentById",
        {
          id: this.configurableDocumentId
        }
      );
        this.allDocumentsData = [];
        if (
          this.getDocumentsDataByConfigurableId &&
          this.getDocumentsDataByConfigurableId.data &&
          this.configurableDocumentData
        ) {
         await this.createDocumentData();
        }
        if (this.allDocumentsData.length) {
          let csvStringArray = [];
          csvStringArray.push(this.tableheaders);
          this.allDocumentsData.forEach((el) => {
            let temp = [];
            this.tableheaders.forEach((key) => {
              if (el[key]) {
                let value = el[key];
                let currencyTpe = this.CurrencyFieldsData.filter((e) => {
                  if (e.label == key && e.type == "CURRENCY") {
                    return e;
                  }
                });
                if (
                  typeof value === "object" ||
                  (typeof value === "string" &&
                    currencyTpe &&
                    currencyTpe.length &&
                    value.includes(","))
                ) {
                  temp.push(`"${value}"`);
                } else if (
                  typeof value === "string" &&
                  currencyTpe &&
                  !currencyTpe.length
                ) {
                  let newValue = value.replaceAll(",", " ");
                  let newValue1 = newValue.replace(/\s+/g, "").trim();

                  temp.push(newValue1);
                } else {
                  let elKey =
                    typeof el[key] === "object" ? el[key].join(" ") : el[key];

                  temp.push(elKey);
                }
              } else {
                temp.push("");
              }
            });
            csvStringArray.push(temp);
          });
          let csvString = "";
          if (csvStringArray && csvStringArray.length) {
            csvStringArray.forEach((field) => {
              csvString += field.join(",");
              csvString += "\r\n";
            });
          }
        await  this.downloadResultCSVFile(csvString);
        }
      await  this.isInProgress_ViewData();
      } catch (err) {
        console.log("downloadCSVFile",err);
      }
    },
    downloadResultCSVFile(data) {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");

      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", `${this.configurableDocumentData.name}.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    getStyle(col) {
      if (col) {
        let style = "color:#F754A2";
        return style;
      }
    },
    isInProgress_ViewData() {
      if (this.is_in_progress) {
        this.allDocumentsData = [];
        this.fetchDocumentData();
        this.tableheaders = [];
        this.allTableHeaders = [];
      } else if (!this.is_in_progress) {
        this.allDocumentsData = [];
        this.fetchDocumentDataNotInprogress();
        this.tableheaders = [];
        this.allTableHeaders = [];
      }
    },
    async fetchDocumentDataNotInprogress() {
      this.loading = true;
      this.loadingText = "Fetching document templates data...";
      let params = {};
      if (this.isAEntityTemplateViewData) {
        params = {
          id: this.configurableDocumentId,
          entity_id: this.$route.params.entity_id,
          entity_data_id: this.entityDataId,
          limit: this.pageSize,
          page: this.page,
        };
      } else {
        params = {
          id: this.configurableDocumentId,
          limit: this.pageSize,
          page: this.page,
        };
      }
      await this.$store.dispatch(
        "documents/fecthDocumentsDataByConfigurableId",
        params
      );
      this.currentPage = this.page;
      this.total =
        this.getDocumentsDataByConfigurableId &&
        this.getDocumentsDataByConfigurableId.total
          ? this.getDocumentsDataByConfigurableId.total
          : 0;
      this.loading = false;
      if (
        this.getDocumentsDataByConfigurableId &&
        this.getDocumentsDataByConfigurableId.data &&
        this.configurableDocumentData
      ) {
        this.createDocumentData();
      }
    },
    async fetchDocumentData() {
      try {
        this.loading = true;
        this.loadingText = "Fetching document templates data...";
        let params = {};
        if (this.isAEntityTemplateViewData) {
          params = {
            id: this.configurableDocumentId,
            entity_id: this.$route.params.entity_id,
            entity_data_id: this.entityDataId,
            include_is_in_progress: true,
            isInProgress: this.is_in_progress,
            limit: this.pageSize,
            page: this.page,
            order_by: this.order_by,
            order_type: this.order_type,
          };
        } else {
          params = {
            id: this.configurableDocumentId,
            include_is_in_progress: true,
            isInProgress: this.is_in_progress,
            limit: this.pageSize,
            page: this.page,
            order_by: this.order_by,
            order_type: this.order_type,
          };
        }
        this.loading = true;

        await this.$store.dispatch(
          "documents/fecthDocumentsDataByConfigurableId",
          params
        );
        this.loading = false;
        this.currentPage = this.page;
        this.total =
          this.getDocumentsDataByConfigurableId &&
          this.getDocumentsDataByConfigurableId.total
            ? this.getDocumentsDataByConfigurableId.total
            : 0;

        this.loading = false;
        if (
          this.getDocumentsDataByConfigurableId &&
          this.getDocumentsDataByConfigurableId.data &&
          this.configurableDocumentData
        ) {
          this.createDocumentData();
        }
      } catch (err) {
        console.log("fetchDocumentData",err);
      }
    },
    testingPackage() {
      console.log(this.tableheaders);
    },
    resetData() {
      this.allDocumentsData = [];
    },
    //save view data
    async SaveUnselectedData() {
      if (this.allTableHeaders) {
        if (this.tableheaders.length) {
          let allData = this.configurableDocumentData;
          allData.pages[0].fields.map((el) => {
            if (el && el.label && this.tableheaders.indexOf(el.label) != -1) {
              el.view_data_field_on = true;
              el.tableheaders_order = this.tableheaders.indexOf(el.label);
            } else {
              el.view_data_field_on = false;
              el.tableheaders_order = 9999;
            }
            return allData;
          });
          let params = {
            id: this.configurableDocumentId,
            view_data: allData,
          };
          await this.$store.dispatch(
            "configurableDocuments/updateViewData",
            params
          );
          if (this.getUpdateViewDataStatus) {
            this.$notify({
              title: "Success",
              message: "Data of this template updated successfully",
              type: "success",
            });
          }
        }
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.isInProgress_ViewData();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.isInProgress_ViewData();
    },
    async loadData(data) {
      if (data && data.type != "init") {
        this.pageSize = data.pageSize;
        this.page = data.page;
        await this.isInProgress_ViewData();
      }
    },
    getCurrencyFormet(field) {
      if (
        field &&
        field.validations &&
        field.validations.currency &&
        field.validations.locale
      ) {
        return {
          currency: field.validations.currency,
          code: field.validations.locale,
        };
      }
      return {
        currency: "USD",
        code: "en-US",
      };
    },
    async createDocumentData() {
      // /let data = "";
      let allFields =
        this.configurableDocumentData &&
        this.configurableDocumentData.pages[0] &&
        this.configurableDocumentData.pages[0].fields
          ? this.configurableDocumentData.pages[0].fields
          : [];
      if (!this.allTableHeaders.length) {
        let newdata = allFields.sort((a, b) =>
          a.tableheaders_order > b.tableheaders_order ? 1 : -1
        );
        await newdata.forEach((el) => {
          if (
            el &&
            el.label &&
            this.unWantedFields.indexOf(el.type) == -1 &&
            (el.type !== "CHECKBOX" && !el.group_key)
          ) {
            this.allTableHeaders.push(el.label);
            if (el.view_data_field_on == true) {
              this.tableheaders.push(el.label);
              this.sorted_array.push(el.label, el.tableheaders_order);
            }
          } else if (
            el &&
            el.group_key &&
            el.group_key !== '' &&
            this.unWantedFields.indexOf(el.type) == -1 &&
            el.type == "CHECKBOX"
          ) {
            if (this.allTableHeaders.indexOf(el.group_key) === -1) {
              this.allTableHeaders.push(el.group_key);
            }
            if (
              el.view_data_field_on == true &&
              this.tableheaders.indexOf(el.group_key) === -1
            ) {
              this.tableheaders.push(el.group_key);
              this.sorted_array.push(el.group_key, el.tableheaders_order);
            }
          }
        });
      }

      this.getDocumentsDataByConfigurableId.data.forEach((data) => {
        this.CurrencyFieldsData = allFields.filter(
          (e) => (e && e.type == "CURRENCY") || e.type == "FORMULA"
        );
        if (data && data.document_data && data.document_status) {
          
          let ob = {};
          let allUpdatedFeilds =
            data?.configurable_document_data &&
            data?.configurable_document_data?.pages[0] &&
            data.configurable_document_data.pages[0]?.fields
              ? data.configurable_document_data.pages[0]?.fields
              : [];
          allUpdatedFeilds.forEach((field) => {
            if (
              field &&
              this.unWantedFields.indexOf(field.type) == -1 &&
              field.key
            ) {
              if (
                field.type === "DATE_SIGNED" &&
                (data.document_data[field.key] || field.value)
              ) {
                const format = store.getters["auth/getDefaultDateFormat"]
                  ? store.getters["auth/getDefaultDateFormat"]
                  : "MM-DD-YYYY";
                let result = data.document_data[field.key] ?  moment(data.document_data[field.key]).format(
                  format
                ) : moment(field.value).format(
                  format
                ) 
                ob[field.label] = result;
              } else if (
                field.type == "DATE_TIME" &&
                data.document_data[field.key]
              ) {
                const format = store.getters["auth/getDefaultDateFormat"]
                  ? store.getters["auth/getDefaultDateFormat"] + " HH:mm:ss A"
                  : "MM-DD-YYYY HH:mm:ss A";
                let result = moment(data.document_data[field.key]).format(
                  format
                );
                ob[field.label] = result;
              } else if (
                field.type == "CURRENCY" ||
                (field.type == "FORMULA" && data.document_data[field.key])
              ) {
                if (typeof data.document_data[field.key] === "string") {
                  const numberValue =
                    data.document_data[field.key] !== undefined ||
                    data.document_data[field.key] !== ""
                      ? data.document_data[field.key].replace(/[^\d.-]/g, "")
                      : 0;
                  const currencyFormat = this.getCurrencyFormet(field);
                  ob[field.label] = Intl.NumberFormat(currencyFormat.code, {
                    style: "currency",
                    currency: currencyFormat.currency,
                  }).format(parseFloat(numberValue ? numberValue : 0));
                } else {
                  const currencyFormat = this.getCurrencyFormet(field);
                  ob[field.label] = Intl.NumberFormat(currencyFormat.code, {
                    style: "currency",
                    currency: currencyFormat.currency,
                  }).format(parseFloat(field.value ? field.value : 0));
                }
              } else if (
                field.type == "DATE_TIME_RANGE" &&
                data.document_data[field.key]
              ) {
                const format = store.getters["auth/getDefaultDateFormat"]
                  ? store.getters["auth/getDefaultDateFormat"] + " HH:mm:ss A"
                  : "MM-DD-YYYY HH:mm:ss A";

                let newAr = [];
                for (var i = 0; i < data.document_data[field.key].length; i++) {
                  const parsedDate = moment(data.document_data[field.key][i]);

                  let formattedDate = parsedDate.format(format);

                  newAr.push(formattedDate);
                }

                let contentValue = newAr.join(" To ");
                ob[field.label] = contentValue;
              } else if (
                field.type == "TIME_RANGE" &&
                data.document_data[field.key]
              ) {
                const format = " HH:mm:ss A";
                let newAr = [];
                for (i = 0; i < data.document_data[field.key].length; i++) {
                  const parsedDate = moment(data.document_data[field.key][i]);

                  let formattedDate = parsedDate.format(format);

                  newAr.push(formattedDate);
                }

                let contentValue = newAr.join(" To ");
                ob[field.label] = contentValue;
              } else if (
                field.type == "DATE_RANGE" &&
                data.document_data[field.key]
              ) {
                const format = store.getters["auth/getDefaultDateFormat"]
                  ? store.getters["auth/getDefaultDateFormat"]
                  : "MM-DD-YYYY";

                let newAr = [];
                for (i = 0; i < data.document_data[field.key].length; i++) {
                  const parsedDate = moment(data.document_data[field.key][i]);

                  let formattedDate = parsedDate.format(format);

                  newAr.push(formattedDate);
                }

                let contentValue = newAr.join(" To ");
                ob[field.label] = contentValue;
              } else if (field.type == "DATE") {
                if (field.is_use_boxes) {
                  ob[field.label] = data.document_data[field.key];
                } else {
                  const format = store.getters["auth/getDefaultDateFormat"]
                    ? store.getters["auth/getDefaultDateFormat"]
                    : "MM-DD-YYYY";
                  let result = moment(data.document_data[field.key]).format(
                    format
                  );
                  ob[field.label] = data.document_data[field.key] ? result : '';
                }
              } else if (
                field.type == "MULTI_SELECT" &&
                data &&
                data.document_data &&
                data.document_data.length &&
                data.document_data[field.key] &&
                typeof data.document_data[field.key] == "object"
              ) {
                ob[field.label] = data.document_data[field.key].join(",");
              } else if (
                field.type == "CHECKBOX" &&
                field.group_key &&
                field.group_key !== '' &&
                data &&
                data.document_data &&
                data.document_data[field.key] &&
                data.document_data[field.key] == true
              ) {
                if (ob[field.group_key]) {
                  let data = [...ob[field.group_key], ...[field.label]];
                  ob[field.group_key] = data;
              } else {
                  ob[field.group_key] = [field.label];
                }
              } else if (field.type != "CHECKBOX" && !field.group_key) {
                ob[field.label] = data.document_data[field.key];
              }
            }
          });
          this.allDocumentsData.push(ob);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-table .cell {
  word-break: break-word;
}

.sidemenu {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto !important;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  //  direction: rtl;
}
.checkbox mb-2 mr-2{
  margin-left: 50%;
}
.sidemenu::-webkit-scrollbar {
  width: 1em;
  height: 0.5em;
}

.sidemenu::-webkit-scrollbar-horizontal {
  /* Select the down or left scroll button when it's being hovered by the mouse */
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}

.sidemenu::-webkit-scrollbar-button:single-button {
  width: 16px;
  height: 13px;
  background-color: #bbbbbb;
  display: block;
  border-style: solid;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement {
  // border-width: 8px 8px 8px 8px;
  // border-color: transparent transparent #555555 transparent;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}

/* Down */
.sidemenu::-webkit-scrollbar-button:single-button:horizontal:increment {
  //  border-width: 8px 8px 8px 8px;
  // border-color: #555555 transparent transparent transparent;
}

.sidemenu::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.custom-pagination {
  background: white;
  padding: 10px 0;
  margin-left: 35%;
}

.paginationSty {
  background: white;
  padding: 10px 0;
  margin-left: 9%;
  margin-top: 25px;
}

//  .pagination-bar {
//     padding: 8px 0;
//     margin-left: 200px;
//   }
//   .el-pagination {
//   background: white;
//    padding: 10px 0;
//     margin-left: 35%;
// }
::v-deep .el-table--scrollable-x .el-table__body-wrapper {
  overflow-x: hidden;
}
</style>
<style>
.el-table .cell {
  word-break: break-word;
}
</style>
